@charset "UTF-8";
.scroll-container[data-v-11e90a5e] {
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
  /* 隐藏默认的滚动条样式 */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}
.scroll-container[data-v-11e90a5e]::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}
.dialogBtn[data-v-11e90a5e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: right;
      -ms-flex-pack: right;
          justify-content: right;
  padding-top: 20px;
  border-top: 1px solid #e6e6e6;
}
.head-search[data-v-11e90a5e] {
  position: relative;
}
.head-search .head-right[data-v-11e90a5e] {
  position: relative;
  left: 1%;
  padding-bottom: 5px;
}
.head-search .head-right .el-input[data-v-11e90a5e] {
  width: 300px;
}
.tag-content[data-v-11e90a5e] {
  padding: 5px 12px;
  background: #fff;
}
.el-pagination[data-v-11e90a5e] {
  text-align: right;
}
[data-v-11e90a5e] .el-input-group__prepend .el-input {
  width: 100px !important;
}
[data-v-11e90a5e] .el-input-group__prepend .el-input .el-input__inner {
  width: 100px !important;
}
[data-v-11e90a5e] .input-with-select .el-input__inner {
  width: 240px;
  margin: 0;
}
[data-v-11e90a5e] .knowledgeCurd .avue-crud .el-table {
  height: calc(100vh - 357px) !important;
  max-height: calc(100vh - 357px) !important;
}